<script setup lang="ts">
defineProps<{ label: string }>()
const emit = defineEmits<{
  click: [e: Event]
}>()
</script>

<template>
  <button
    class="hover:border-primary hover:bg-primary/5 hover:text-primary flex select-none items-center gap-1 self-start rounded-lg bg-gray-800 p-2 text-xs font-normal text-gray-500 underline-offset-2 hover:underline"
    @click.stop="emit('click', $event)"
    @keyup.enter.stop="emit('click', $event)"
  >
    <Icon name="chart-star-circle" filled />
    Show by {{ label }}
  </button>
</template>
